// For auth actions
export const GET_ERRORS = "GET_ERRORS"
export const USER_LOADING = "USER_LOADING"
export const SET_CURRENT_USER = "SET_CURRENT_USER"

// For region actions
export const REMOVE_REGION = "REMOVE_REGION"
export const LIST_REGIONS = "LIST_REGIONS"
export const UPDATE_REGION = "UPDATE_REGION"
export const CREATE_REGION  = "CREATE_REGION"
export const GET_REGION  = "GET_REGION"
export const GET_REGION_DONATION  = "GET_REGION_DONATION"

// For multi-sig actions
export const DEPOSIT_FUNDS_TO_MULTI_SIG = "DEPOSIT_FUNDS_TO_MULTI_SIG"
export const ADD_OWNER_TO_MULTI_SIG = "ADD_OWNER_TO_MULTI_SIG"
export const REMOVE_OWNER_FROM_MULTI_SIG = "REMOVE_OWNER_FROM_MULTI_SIG"
export const REPLACE_OWNER_IN_MULTI_SIG = "REPLACE_OWNER_IN_MULTI_SIG"
export const CHANGE_REQUIREMENT_FROM_MULTI_SIG = "CHANGE_REQUIREMENT_FROM_MULTI_SIG"
export const SUBMIT_TRANSACTION_FROM_MULTI_SIG = "SUBMIT_TRANSACTION_FROM_MULTI_SIG"
export const CONFIRM_TRANSACTION_FROM_MULTI_SIG = "CONFIRM_TRANSACTION_FROM_MULTI_SIG"
export const REVOKE_CONFIRMATION_FROM_MULTI_SIG = "REVOKE_CONFIRMATION_FROM_MULTI_SIG"
export const EXECUTE_TRANSACTION_FROM_MULTI_SIG = "EXECUTE_TRANSACTION_FROM_MULTI_SIG"
export const IS_CONFIRMED_FROM_MULTI_SIG = "IS_CONFIRMED_FROM_MULTI_SIG"

// For whitelist actions
export const ADD_WHITELISTED = "ADD_WHITELISTED"
export const ADD_WHITELIST_ADMIN = "ADD_WHITELIST_ADMIN"
export const RENOUNCE_WHITELISTED = "RENOUNCE_WHITELISTED"
export const RENOUNCE_WHITELIST_ADMIN = "RENOUNCE_WHITELIST_ADMIN"
export const REMOVE_WHITELISTED = "REMOVE_WHITELISTED"
export const GET_WHITELIST_FOR_COUNTRY = "GET_WHITELIST_FOR_COUNTRY"